import { Button } from "@/components/ui/button";
import {
    Zap,
    Target,
    Users,
    FileText,
    BarChart,
    Download,
    Repeat,
    Coffee,
    Mail,
} from "lucide-react";
import { useState } from "react";
import { cn } from "@/lib/utils";
import { Switch } from "@/components/ui/switch";
import { Link } from "@remix-run/react";

const proPlan = {
    name: "Pro Plan",
    prices: {
        monthly: 12,
        quarterly: 33,
        yearly: 120,
    },
    regularPrices: {
        monthly: 24,
        yearly: 240,
    },
    description: "Everything you need to create tailored CVs that stand out",
    features: [
        { icon: Zap, text: "AI-powered CV customization" },
        { icon: Target, text: "Unlimited CV tailorings" },
        // { icon: Clock, text: "Priority email support" },
        { icon: Users, text: "CV performance analytics" },
        { icon: FileText, text: "Multiple CV templates" },
        { icon: Mail, text: "Personalized cover letter generator" },
        { icon: BarChart, text: "Job market insights", comingSoon: true },
        { icon: Download, text: "Export to multiple formats" },
        { icon: Repeat, text: "Unlimited revisions" },
    ],
    cta: "Get Started Now",
};

const DECIMAL_PLACES = 0;
const AVERAGE_COFFEE_PRICE = 3.5; // Average price of a cup of coffee

export default function PricingSection() {
    const [billingCycle, setBillingCycle] = useState<"monthly" | "yearly">("yearly");

    const getMonthlyPrice = () => {
        switch (billingCycle) {
            case "monthly":
                return proPlan.prices.monthly;
            case "yearly":
                return proPlan.prices.yearly / 12;
        }
    };

    const getBillingNote = () => {
        switch (billingCycle) {
            case "monthly":
                return "billed monthly";
            case "yearly":
                return "billed yearly";
        }
    };

    const getSavings = () => {
        switch (billingCycle) {
            case "monthly":
                return null;
            case "yearly":
                return `Save $${(proPlan.prices.monthly * 12 - proPlan.prices.yearly).toFixed(DECIMAL_PLACES)} (${(
                    (1 - proPlan.prices.yearly / (proPlan.prices.monthly * 12)) *
                    100
                ).toFixed(DECIMAL_PLACES)}%)`;
        }
    };

    const getRegularPrice = () => {
        if (billingCycle === "monthly") {
            return `$${proPlan.regularPrices.monthly.toFixed(DECIMAL_PLACES)}/month`;
        }
        if (billingCycle === "yearly") {
            return `$${(proPlan.regularPrices.yearly / 12).toFixed(DECIMAL_PLACES)}/month`;
        }
    };

    const getCoffeeComparison = () => {
        const monthlyPrice = getMonthlyPrice();
        const dailyPrice = monthlyPrice / 30; // Assuming 30 days in a month
        const coffeesPerMonth = monthlyPrice / AVERAGE_COFFEE_PRICE;
        return `Less than ${dailyPrice.toFixed(2)} per day - that's only ${coffeesPerMonth.toFixed(1)} cups of coffee per month!`;
    };

    return (
        <section className="" id="pricing">
            <div className="container mx-auto px-4">
                <h2 className="bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 bg-clip-text pb-4 text-center text-3xl font-bold tracking-tighter sm:text-5xl text-transparent">
                    Simple, Transparent Pricing
                </h2>
                <p className="mb-12 text-center text-gray-600 text-xl">
                    No hidden fees. No contracts. Cancel anytime. Less than a cup of coffee a day!
                </p>
                <div className="mb-8 flex items-center justify-center">
                    <span
                        className={cn(
                            "mr-2 font-semibold",
                            billingCycle === "monthly" ? "text-purple-600" : "text-gray-500",
                        )}
                    >
                        Monthly
                    </span>
                    <Switch
                        checked={billingCycle === "yearly"}
                        onCheckedChange={(checked) =>
                            setBillingCycle(checked ? "yearly" : "monthly")
                        }
                        className="data-[state=checked]:bg-purple-600"
                    />
                    <span
                        className={cn(
                            "ml-2 font-semibold",
                            billingCycle === "yearly" ? "text-purple-600" : "text-gray-500",
                        )}
                    >
                        Annual
                    </span>
                    <span className={cn("ml-4 font-medium text-purple-600 text-xs italic")}>
                        Save with annual
                    </span>
                </div>
                <div className="mx-auto max-w-5xl overflow-hidden rounded-2xl bg-white shadow-xl">
                    <div className="md:flex">
                        <div className="bg-gradient-to-br from-purple-600 to-indigo-600 p-8 text-white md:w-1/2 md:p-12">
                            <h3 className="mb-4 font-bold text-3xl">{proPlan.name}</h3>
                            <div className="mb-4">
                                <span className="font-bold text-5xl">
                                    ${getMonthlyPrice().toFixed(DECIMAL_PLACES)}
                                </span>
                                <span className="text-xl">/month</span>
                            </div>
                            <p className="mb-2 text-purple-100">{getBillingNote()}</p>
                            {getSavings() && (
                                <p className="mb-2 font-semibold text-yellow-300">{getSavings()}</p>
                            )}
                            <p className="mb-6 text-purple-100">{proPlan.description}</p>
                            <div className="mb-6 flex items-center text-yellow-300">
                                <Coffee className="mr-2 h-5 w-5" />
                                <p>{getCoffeeComparison()}</p>
                            </div>
                            <div className="-rotate-2 mb-6 transform rounded-lg bg-yellow-400 p-4 text-purple-900 shadow-md transition-transform duration-300 hover:rotate-0">
                                <p className="mb-2 font-bold text-lg">Limited Time Offer!</p>
                                <p>
                                    Subscribe now and lock in this price forever. Regular price:{" "}
                                    {getRegularPrice()}
                                </p>
                            </div>

                            <div className="mb-4 text-center font-semibold text-yellow-300">
                                Start with a 3-day free trial!
                            </div>
                            <Button
                                className="w-full bg-white font-bold text-purple-600 text-xl transition-all duration-300 hover:scale-105 hover:bg-white plausible-event-name=Subscribe"
                                asChild
                            >
                                <Link to="/auth/signup">{proPlan.cta}</Link>
                            </Button>
                        </div>
                        <div className="p-8 md:w-1/2 md:p-12">
                            <h4 className="mb-6 font-semibold text-gray-800 text-xl">
                                What's included:
                            </h4>
                            <ul className="grid gap-x-4 space-y-4">
                                {proPlan.features.map((feature) => (
                                    <li
                                        key={feature.text}
                                        className="flex items-center text-gray-600"
                                    >
                                        <feature.icon className="mr-3 h-5 w-5 text-green-500" />
                                        <span
                                            className={cn(
                                                "text-lg",
                                                feature.comingSoon && "text-gray-400",
                                            )}
                                        >
                                            {feature.text}{" "}
                                            {feature.comingSoon && (
                                                <span className="ml-2 rounded-full bg-gray-200 px-2 py-1 text-gray-600 text-xs">
                                                    Coming Soon
                                                </span>
                                            )}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mt-8 text-center text-gray-600">
                    <p>Flexible subscriptions to fit your needs:</p>
                    <ul className="mt-4 space-y-2">
                        <li>
                            ✓ 3-day free trial - try out TailoredCV.ai with no limits for 3 days
                        </li>
                        <li>✓ Cancel anytime with no commitments</li>
                        <li>✓ Pause your subscription when you don't need it</li>
                        <li>✓ Easily restart your plan when you're ready to job hunt again</li>
                    </ul>
                </div>
            </div>
        </section>
    );
}
